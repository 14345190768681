@use "../../config/variables.scss" as global;
.contactScreen {
  margin-top: 100px;
}
.contactScreen-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 768px) {
    //   background:linear-gradient(
    //   to right,
    //   white 0%,
    //   white 50%,
    //   global.$primary 50%,
    //   global.$primary 100%
    // );
  }
}
.contactScreen-box1 {
  background: rgb(255 255 255);
  border-radius: 4px;
  margin-top: 20px;
  transition: 0.3s;
  position: sticky;
  top: 4.375rem;
}

.contactScreen-txt1 {
  font-family: global.$Medium;
  color: #1a1a1a;
  font-size: 28px;
  margin-bottom: 10px;
}
