body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "DMSans-Bold";
  src: url("./assets/fonts/DMSans-Bold.ttf") format("truetype");
}


@font-face {
  font-family: "DMSans-ExtraBold";
  src: url("./assets/fonts/DMSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-SemiBold";
  src: url("./assets/fonts/DMSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("./assets/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("./assets/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Italic";
  src: url("./assets/fonts/DMSans-Italic.ttf") format("truetype");
}


.table-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 10px;
}

.form-header {
  background-color: rgb(241, 249, 255);
  padding: 5px 10px;
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}

.form-label {
  color: rgb(89, 89, 89);
  font-size: 13px;
  font-weight: 600;

}

.LoadingBox {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}