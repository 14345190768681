@use "../../config/variables.scss" as vars;
.LoginScreen {
  height: 100vh;
  display: flex;
  align-items: center;
}

.LoginScreen-Box {
  padding: 10px;
}

.LoginScreen-txt1 {
  text-align: center;
  margin-bottom: 20px;
  font-family: vars.$SemiBold;
  font-size: 25px;
}
