@use "../../config/variables.scss" as vars;
.PageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  border-bottom: 1px solid rgb(186, 186, 186);
}

.PageHeader-back {
  font-size: 30px;
  border-right: 1px solid rgb(186, 186, 186);
  padding-right: 20px;
  margin-right: 20px;
}

.PageHeader-Box {
  font-family: vars.$Regular;
}

.PageHeader-text1 {
  font-size: 20px;
  font-family: vars.$SemiBold;
}
