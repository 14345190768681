@use "../../config/variables.scss" as vars;

.AdminRoute-box {
  height: 91vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.AdminHeader {
  height: 9vh;
  background-color: #fff;
  border-bottom: 1px solid rgb(234, 234, 234);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.AdminHeaderBox1 {
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgb(234, 234, 234);
  img {
    width: 170px;
    height: 70px;
    object-fit: contain;
  }
}
.AdminHeaderBox2 {
  padding-left: 10px;
  margin-right: 20px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid rgb(234, 234, 234);
}
.AdminHeadertxt {
  flex: 1;
  padding-left: 20px;
  font-size: 19px;
  font-family: vars.$Bold;
  color: "#000";
}
.AdminHeadertxt1 {
  color: #000;
  font-size: 15px;
  font-family: vars.$SemiBold;
  margin-bottom: 5px;
}
.AdminHeaderBox3 {
  padding-right: 40px;
  color: rgb(213, 213, 213);
}
.AdminHeaderButton {
  cursor: pointer;
  margin-right: 40px;
  padding: 7px 30px;
  font-family: vars.$SemiBold;
  color: #000;
  border:1px solid rgb(243, 243, 243);
  &:hover {
    background-color: rgb(234, 234, 234);
    border-radius: 2px;
  }
}
.AdminHeadertime {
  background-color: rgb(234, 234, 234);
  margin-right: 40px;
  padding: 7px 30px;
  font-family: vars.$SemiBold;
  color: #000;
  border-radius: 2px;
}
