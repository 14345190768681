@use "../../config/variables.scss" as vars;
.PageHeader-children {
  display: flex;
  align-items: center;
}
.PageHeader-gym-name {
  font-family: vars.$SemiBold;
}
// -- info small cards ----
.Info-cards {
  // background-color: #cbe5e1a3;
  background-color: #266adb24;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.Info-cardBox1 {
  background-color: #fff;
  height: 50px;
  width: 50px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 30px;
  color: #000;
}
.Info-cardtxt1 {
  font-size: 14px;
  color: rgb(90, 90, 90);
}
.Info-cardtxt2 {
  font-size: 25px;
  color: #000;
  font-family: vars.$Bold;
}
.Info-details {
  display: flex;
  line-height: 2;
  margin: 5px 0px;
}
.Info-details-txt1 {
  margin-inline: 3px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: vars.$Medium;
  text-wrap: wrap;
}
.Info-details-txt2 {
  margin-inline: 5px;
  font-size: 15px;
  font-family: vars.$ExtraBold;
  flex-wrap: wrap;
}
.Info-limit-count {
  border-radius: 8px;
  padding: 0px 8px;
}
.bgc-red {
  background-color: rgb(255 142 142 / 44%);
}
.bgc-green {
  background-color: rgb(164 217 158 / 44%);
}
.bgc-blue {
  background-color: rgb(94 155 255 / 44%);
}
.Info-width {
  width: 130px;
  text-wrap: wrap;
  justify-content: flex-start;
}

.tabs-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: -7px;
  font-family: vars.$Bold;
  font-size: 16px;
}