 * {
   box-sizing: border-box;
 }

 .Invoice-container {
   width: 100%;
   height: 100vh;
   display: flex;
   padding: 10px;
   justify-content: center;
   background-color: #fff;
 }

 .Invoice-innerBox {
   height: 80vh;
   background-color: #fff;
   border-radius: 8px;
   padding: 25px;
   border: 1px solid #373737;

 }

 .Invoice-profilePic {
   min-width: 110px;
 }

 .Invoice-LogoAndName {
   display: flex;
   width: 100%;
   height: 100px;
 }

 .Invoice-OfficeName {
   font-size: 20px;
   font-weight: bolder;
 }

 .Invoice-OfcDetails {
   display: flex;
   flex-direction: column;
   justify-content: center;
 }

 .Invoice-billNameAndDate {
   font-size: medium;
   font-weight: 600;
   width: 100%;
   display: flex;
   justify-content: space-between;
 }

 .Invoice-Details {
   width: 100%;
   margin: 5px 0px;
   font-size: 16px;
   padding: 9px 15px;
   display: flex;
   justify-content: space-between;
 }

 .Invoice-Details-Title {
   border-radius: 5px;
   background-color: #e1e1e1
 }

 .Invoice-Details-Total {
   width: 50%;
 }