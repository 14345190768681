@use "../../config/variables.scss" as vars;
.Header {
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 9vh;
  width: 100%;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    background-color: #fff;
    box-shadow: 0 -6px 8px 5px rgba(0, 0, 0, 0.278);
  }
  &.Scrolled {
    background-color: #fff;
    box-shadow: 0 -6px 8px 5px rgba(0, 0, 0, 0.278);
  }
}
.Header-Box {
  display: flex;
  width: 100%;
  height: 9vh;
  align-items: center;
  justify-content: center;
}
.Header-Box2 {
  flex: 1;
  display: flex;
  gap: 16px;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
}
.Header-text1 {
  display: flex;
  gap: 8px;
  width: fit-content;
  height: fit-content;
  padding: 8px 16px 8px 16px;
  font-family: vars.$Medium;
  font-size: 15px;
}

.Header-Box1 {
  flex: 1;
}

.Header-logo {
  width: 250px;
  height: 200px;
  object-fit: contain;
}

.Header-Box3 {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
