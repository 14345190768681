@use "../../config/variables.scss" as vars;
.HomeScreen {
  overflow: hidden;
}
.HomeScreen-Section1 {
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin: -20px;
  }
}

.HomeScreen-img1 {
  width: 100%;
  object-fit: contain;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin-top: 9vh;
    height: 70vh;
    object-fit: cover;
  }
}

.HomeScreen-Box1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin: 20px;
    margin-top: -60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.HomeScreen-Box2 {
  display: flex;
  align-items: flex-end;
}

.HomeScreen-text1 {
  font-size: 30px;
  font-family: vars.$SemiBold;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 20px;
  }
}

.HomeScreen-text2 {
  font-size: 40px;
  font-family: vars.$Bold;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 30px;
  }
}

.HomeScreen-btn {
  background-color: vars.$secondary;
  width: 40%;
  padding: 10px 10px;
  text-align: center;
  font-family: vars.$SemiBold;
}

.HomeScreen-text3 {
  font-size: 14;
  font-family: vars.$Medium;
  color: grey;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    text-align: center;
  }
}

//section 2
.HomeScreen-Section2-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px 0px 0px 0px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 24px;
    border-radius: 0px;
  }
}
.HomeScreen-Section2-Box2 {
  padding: 30px;
  height: 100%;
  background-color: #f8f8f8;
  border-radius: 0px 40px 40px 0px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    border-radius: 0px;
    font-size: 24px;
    padding: 20px;
    background-color: #fff;
  }
}
.HomeScreen-section2-text1 {
  font-size: 34px;
  margin-bottom: 20px;
  font-family: vars.$Bold;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 24px;
    text-align: center;
  }
}
.HomeScreen-section2-text2 {
  font-size: 18px;
  font-family: vars.$SemiBold;
}
.HomeScreen-section2-text3 {
  font-size: 14px;
  font-family: vars.$Regular;
  margin-bottom: 30px;
  color: #858585;
}
.HomeScreen-icon-container {
  background-color: vars.$secondary;
  padding: 10px;
  display: inline-block;
  border-radius: 24px;
  margin-bottom: 20px;
}
//section 3
.HomeScreen-Section3-text3 {
  font-size: 18px;
  font-family: vars.$Regular;
  color: #858585;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
    white-space: nowrap;
  }
}
.HomeScreen-Section3-text1 {
  font-size: 30px;
  font-family: vars.$Bold;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 20px;
  }
}
.HomeScreen-Section3-img {
  object-fit: contain;
  width: 100%;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin-bottom: 20px;
  }
}
//section4
.HomeScreen-Section4 {
  background-color: #f5f8fb;
  padding: 40px 100px 40px 100px;
  @media screen and (max-width: 999px) {
    padding: 10px 20px 10px 20px;
  }
  border-radius: 40px;
}
.HomeScreen-section4-text1 {
  text-align: center;
  font-size: 34px;
  font-family: vars.$Bold;
  margin-bottom: 20px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 24px;
  }
}
//section 5
.HomeScreen-section5 {
  padding: 40px 100px 40px 100px;
  @media screen and (max-width: 999px) {
    padding: 10px 20px 10px 20px;
  }
}
.HomeScreen-Section5-img {
  object-fit: contain;
  width: 100%;
}
.HomeScreen-section5-text1 {
  font-size: 34px;
  font-family: vars.$Bold;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 24px;
  }
}

.HomeScreen-section5-text2 {
  font-size: 16px;
  font-family: vars.$Regular;
  margin-bottom: 1rem;
}
.HomeScreen-section5-list {
  padding-left: 1rem !important;
  width: 100%;
  font-size: 16px;
  font-family: vars.$Medium;
  li {
    color: vars.$secondary;
    span {
      color: black;
    }
  }
}
.HomeScreen-section5-box1 {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  align-items: center;
}
.HomeScreen-section5-btn {
  background-color: vars.$secondary !important;
  color: black !important;
  border: none !important;
  font-family: vars.$SemiBold !important;
  font-size: 16px !important;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin-bottom: 1rem;
  }
}

// section-6
.HomeScreen-section6-text1 {
  font-size: 34px;
  font-family: vars.$Bold;
  text-align: center;
  margin-bottom: 2rem;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 24px;
  }
}
.HomeScreen-section6-Box1 {
  display: flex !important;
  justify-content: space-between !important;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    justify-content: space-around !important;
  }
}
.HomeScreen-section6-Box2 {
  padding: 2rem !important;
  background-color: #f5f8fb;
  border-radius: 24px;
  @media screen and (min-width: 320px) and (max-width: 1024px) {
    margin-bottom: 20px;
  }
}
.HomeScreen-section6-text2 {
  font-size: 20px;
  font-family: vars.$SemiBold;
}
.HomeScreen-section6-text3 {
  font-size: 50px;
  font-family: vars.$Bold;
}
.HomeScreen-section6-text4 {
  font-size: 20px;
  font-family: vars.$Medium;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
}
.HomeScreen-section6-text5 {
  margin-top: 1rem;
  font-size: 20px;
  font-family: vars.$SemiBold;
}
.HomeScreen-section6-ul {
  list-style-type: none;
  padding-left: 0px;
}
