@use "../../config/variables.scss" as vars;
.Footer {
  background-color: black;
  padding: 20px 150px 20px 150px;
  color: #d1d1d1;
  font-family: vars.$Regular;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 20px;
  }
}
.Footer-logo {
  object-fit: contain;
  width: 100%;
  margin-bottom: 10px;
}
.Footer-text1 {
  color: vars.$secondary;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.Footer-Box1 {
  @media screen and (min-width: 320px) and (max-width: 600px) {
    margin-bottom: 20px;
    text-align: center;
  }
}

.Footer-Box2 {
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin-bottom: 20px;
    text-align: center;
  }
}
.Footer-Box-Btn1 {
  cursor: pointer;
  width: 117px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.Footer-Box-Btn2 {
  background-color: vars.$secondary;
}
